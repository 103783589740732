import React from "react"

import Layout from "../../components/layout"
import * as styles from '../../styles/pages/contact-form.module.css'

import ContactForm from "../../components/contactform"


const ContactFormPage = () => (
  <Layout mobileTitle={true} activePath='contact' fullPath='contact/form' pageTitle="Contact Form">
  	<div className={styles.formholder}>
		<ContactForm mobileDisplay={true} showLabel={false}/>
	</div>
  </Layout>
)

export default ContactFormPage
